<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts">
import { environment } from "@/environment";
import firebase from "firebase/app";

firebase.initializeApp(environment.firebase);
console.log("firebaseApp Init complete");

import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "iSUGEZT",
  components: {
    IonApp,
    IonRouterOutlet,
  },
});
</script>