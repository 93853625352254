import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '../views/Tabs.vue'
import { isPlatform } from '@ionic/vue';
import {
  Plugins
} from "@capacitor/core";
const { Storage } = Plugins;

import firebase from 'firebase/app';
import 'firebase/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/home',
  },
  {
    path: '/first-time',
    component: () => import('@/views/FirstTime.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('@/views/SignIn.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('@/views/SignUp.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/home'
      },
      {
        path: 'home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: 'history',
        component: () => import('@/views/History.vue')
      },
      {
        path: 'profile',
        component: () => import('@/views/Profile.vue')
      }
    ],
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  let firstTimeTutorialCompleted = false;
  Storage.get({ key: 'first-time-tutorial-completed' }).then(ValueObject => {
    firstTimeTutorialCompleted = ValueObject.value == "true";
  }).finally(() => {
    if (requiresAuth && (!currentUser || currentUser === null)) {
      if (firstTimeTutorialCompleted || isPlatform("desktop")) {
        next('sign-in')
      } else {
        next('first-time')
      }
    }
    else if (!requiresAuth && currentUser) next('/tabs/home')
    else next()
  })
});

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    router.replace("/tabs/home");
  } else {
    // No user is signed in.
    if (router.currentRoute.value.meta.requiresAuth) {
      router.replace("/sign-in");
    }
  }
});

export default router
